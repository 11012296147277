import createStore from "./createStore";

const initialState = {
    details: true,
    utc: false
};

const logStore = (store) => {
    console.log(store);
};

const useObject = createStore(initialState, { logStore });

export default useObject;
