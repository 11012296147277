import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import useObject from "../store/useObject";

export default function TopController() {

    const [{details, utc}, setObj, { logStore }] = useObject(true, (state) => state);
    const handleGeneric = (fn) => () => {
        setObj(fn);
        logStore();
    };
    return <div style={{
        position: "absolute",
        top: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        fontSize: "0.75rem"
    }}>
        <FormControlLabel
            control={<Switch checked={details ?? true}
                             onChange={handleGeneric(state => ({...state, details: !state.details}))}
                             name="details"
                             size={"small"}
                             color={"default"}/>}
            label={<FontAwesomeIcon icon={faInfo} size={"xs"}/>}/>
        <FormControlLabel
            control={<Switch checked={utc ?? false}
                             onChange={handleGeneric(state => ({...state, utc: !state.utc}))}
                             name="utc"
                             size={"small"}
                             color={"default"}/>}
            label={"utc"}/>
    </div>;
}
