import logo from './logo.svg';
import './App.css';
import Welcome from './Components/Welcome'
import ConfigLoader from "./Components/ConfigLoader";

function App() {
    return (
        <ConfigLoader splash={<div>Loading Config</div>}>
            <div className="App">
                    <div className="Image-Header">
                        <img style={{"width": "200px"}} src="./Envirosuite-logo-colour-RGB.png"/>
                        <div>Service Status Dashboard</div>
                    </div>
                    <Welcome/>
            </div>
        </ConfigLoader>
    );
}

export default App;
