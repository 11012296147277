import React, {useEffect, useState} from "react";
import StateToReact from "../State";
import {config} from "./ConfigLoader";

export default function Welcome()  {
    const [data, setData] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [wrfData, setwrfData] = useState(null);

    const {state_files_url} = config;

    async function LoadStatus(fn, url) {
        setLoading(true);
        const response = await fetch(url);
        const json = await response.json();
        fn(json);
        setLoading(false);
    }
    useEffect(() => {
        const url = `${state_files_url}/state-wrf.json`;
        LoadStatus(setwrfData, url).catch(console.error);
        const interval = setInterval(() => LoadStatus(setwrfData, url), 1000 * 10); //refresh once every 10 seconds
        return () => clearInterval(interval);
    }, [state_files_url]);
    useEffect(() => {
        const url = `${state_files_url}/state.aws-global.json`;
        LoadStatus(setData, url).catch(console.error);
        const interval = setInterval(() => LoadStatus(setData, url), 1000 * 10); //refresh once every 10 seconds
        return () => clearInterval(interval);
    }, [state_files_url]);

    return data === null ? "Loading":
        <StateToReact data={data} wrfData={wrfData} isLoading={isLoading} />;
}