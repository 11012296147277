import {useFetch} from "../hooks";


export const config = {};

export default function ConfigLoader(props) {
    const [data, loading] = useFetch("./appconfig.json")

    if(loading) {
        return <>{props.splash}</>;
    } else {
        Object.getOwnPropertyNames(data).forEach(n => {
           config[n] = data[n];
        });
        return props.children;
    }
}