import { useState, useMemo, useEffect, useLayoutEffect } from "react";

const effect = typeof window === "undefined" ? useEffect : useLayoutEffect;

const setState = (store, newState) => {
    const state =
        typeof newState === "function" ? newState(store.state) : newState;
    if (state !== store.state) {
        store.state = state;
        store.listeners.forEach((listener) => {
            listener.run(store.state);
        });
    }
};

const associateActions = (store, actions) => {
    const associatedActions = {};
    if (actions) {
        Object.keys(actions).forEach((key) => {
            if (typeof actions[key] === "function")
                associatedActions[key] = actions[key].bind(null, store);
        });
    }
    return associatedActions;
};

const useStore = (store, render = true, mapState, mapActions) => {
    const [, originalHook] = useState(Object.create(null));
    const state = mapState ? mapState(store.state) : store.state;
    const actions = useMemo(
        () => (mapActions ? mapActions(store.actions) : store.actions),
        [mapActions, store.actions]
    );

    effect(() => {
        if (render) {
            const newListener = { oldState: {}, run: null };
            newListener.run = mapState
                ? (newState) => {
                    const mappedState = mapState(newState);
                    if (mappedState !== newListener.oldState) {
                        newListener.oldState = mappedState;
                        originalHook(mappedState);
                    }
                }
                : originalHook;

            store.listeners.add(newListener);
            newListener.run(store.state);
            return () => {
                store.listeners.delete(newListener);
            };
        }
    }, []); // eslint-disable-line

    return [state, store.setState, actions];
};

const createStore = (initialState, actions) => {
    const store = {
        state: initialState,
        listeners: new Set(),
        setState: null,
        actions: null
    };
    store.setState = setState.bind(null, store);
    store.actions = associateActions(store, actions);
    return useStore.bind(null, store);
};

export default createStore;
