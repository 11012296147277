import React,  { useState, useEffect }  from 'react';
import {DateTime} from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle, faSpinner, faQuestion, faInfo, faHistory, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip';
import './state.css';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TopController from "./Components/TopControls";
import useObject from "./store/useObject";



function StateToReact({data, isLoading, wrfData} ) {
    return <div style={{"width": "80%"}}>
        <TopController />
        <div style={{"display": "flex", "justifyContent": "center", "alignItems": "center"}}>
            <CurrentClock /> &nbsp;
            <FontAwesomeIcon icon={faSpinner} spin={true} style={{"visibility": isLoading ? "visible" : "hidden"}}/>
        </div>

        <ReactTooltip/>
        <PrintAWSGlobal data={data} name={"AWS Global"}/>

        <br/>
            <PrintWrfRuns wrfData={wrfData} />
    </div>;
}

function CurrentClock() {
    const [time, setTime] = useState(null);
    useEffect(() => {
        setTime(DateTime.utc())
        const interval = setInterval(() => setTime(DateTime.utc()), 1000); //refresh once every 10 seconds
        return () => clearInterval(interval);
    }, []);
    return <div>Current Status: <PrintTime time={time} /></div>;
}

function PrintTime({time}) {
    const [utc] = useObject(true, (state) => state.utc);
    if(!time){
        time = DateTime.utc();
    }
    if(!time.isLuxonDateTime){
        time = DateTime.fromISO(time);
    }

    if(utc) {
        time = time.toUTC();
    } else {
        time = time.toLocal()
    }
    return <>{time.toLocaleString(DateTime.TIME_WITH_SECONDS)}</>;
}

function PrintAWSGlobal( {name, data}) {
   return <div>
            <div className="status-line">
                <div className="status-section" colSpan={2}>{name}</div>
            </div>
            {Object.getOwnPropertyNames(data).map(d => <StatusEntry1 statusResult={data[d]} />)}
        </div>;
}

function StatusEntry1({statusResult}) {
    const name = statusResult.Title;
    const shortName = statusResult.Description;
    const [details] = useObject(true, (state) => state.details);

    return <div className="status-line">
        <div className="status-icon">{State(statusResult, shortName)}</div>
        <div>{name}</div>
        <div className="status-details" hidden={!details}>
            {statusResult.LongDescription}
            Last {shortName}: <PrintTime time={statusResult.LastTime} /> ({timeAgo(statusResult.LastTime)})
        </div>
    </div>
}



function PrintWrfRuns({wrfData}) {
    const [wrfDate, setwrfDate] = useState(DateTime.utc().toFormat("yyyy-MM-dd"));
    return <div>
        <ReactTooltip/>
        <div className="status-line">
            <div className="status-section" colSpan={2}>WRF Runs&nbsp;
                <Select
                    value={wrfDate}
                    onChange={(event) => setwrfDate(event.target.value)}
                    name={'wrfDate'}>
                    {[3, 2, 1, 0].map(t => {
                        const prev = DateTime.utc().plus({days: -t});
                        return <MenuItem key={prev}
                                         value={prev.toFormat('yyyy-MM-dd')}>{prev.toFormat('yyyy-MM-dd')}</MenuItem>
                    })}
                </Select>
            </div>
        </div>
        <PrintWrfItems wrfData={wrfData} wrfDate={wrfDate}/>
    </div>;
}

function PrintWrfItems({wrfData, wrfDate}){
    const [details] = useObject(true, (state) => state.details);

    if(!wrfData || !wrfData[wrfDate]){
        return <></>;
    }

    const configs = Object.getOwnPropertyNames(wrfData[wrfDate]);
    return <>{configs.map(config => {
        const times = Object.getOwnPropertyNames(wrfData[wrfDate][config]);
        const failed = times.filter(d => !wrfData[wrfDate][config][d]);
        const text = `${times.length - failed.length}/${times.length} sucessful configuration runs`
        const state = failed.length === 0 ? "Good" : failed.length === times.length ? "Bad" : "Warning";
        return <div className="status-line" key={config}>
            <div className="status-icon">
                <StateIcon state={state} msg={text}/></div>
            <div>{config}</div>
            <div className="status-details" hidden={!details} >
                {text}
            </div>
        </div>;
    })}</>;
}


const units = [
    'year',
    'month',
    'week',
    'day',
    'hour',
    'minute',
    'second',
];

const timeAgo = (date) => {
    let dateTime = DateTime.fromISO(date)
    const diff = dateTime.diffNow().shiftTo(...units);
    const unit = units.find((unit) => diff.get(unit) !== 0) || 'second';

    const relativeFormatter = new Intl.RelativeTimeFormat('en', {
        numeric: 'auto',
    });
    return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
};

function State(statusResult, name) {
    let {State, ShortDescription, LastTime, ValidTill} = statusResult;

    if(ValidTill && DateTime.fromISO(ValidTill).diffNow().milliseconds <= 0){
        State = 'Bad';
        ShortDescription = `Status stale. Last verified ${timeAgo(ValidTill)}`;
    }
    if(!ShortDescription && LastTime) {
        ShortDescription = `Last ${name ?? "event"} ${timeAgo(LastTime)}`;
    }

    return <>
        <StateIcon state={State} msg={ShortDescription}/>
    </>
}

function StateIcon({state, msg}) {
    const styles = {
        Bad:  ["#c90a0a", faTimesCircle],
        Warning: ["rgba(245,138,65,0.84)", faExclamationCircle],
        Good: ["#367810", faCheckCircle],
        Unknown: ["#c90a0a", faQuestion]
    }
    const [color, icon] = (styles[state] || styles["Bad"]);
    return <>
        <FontAwesomeIcon icon={icon} color={color} data-tip={msg}/>
    </>
}

export default  StateToReact;